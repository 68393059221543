@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/Muli.woff') format('woff');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'AveriaSerifLibre';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('/fonts/AveriaSerifLibre-Regular.woff2') format('woff2');
  }

  body {
    padding: 0;
    margin: 0;
    color: #070E2F;
    font-family: 'Mulish', sans-serif;
  }

  a {
    @apply text-chlinks;
  }
  a:hover {
    @apply text-chlinks-800;
  }
  
  .socks:after { 
    content: '';
    position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
    height: 12px; /* Total height of the border */
    background: linear-gradient(to bottom, #FF9E00 33%, #F9F3E6 33%, #F9F3E6 66%, #FF9E00 66%);
  }

  .masked {
    -webkit-mask-image: linear-gradient(to top, transparent 2%, black 85%);
    mask-image: linear-gradient(to top, transparent 2%, black 85%);
  }
  
  .mapPopup { /* used in mapbox component to ensure popup isn't below avatar images, especially self */
    z-index: 11;
  }
}
